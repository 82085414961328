import * as Sentry from '@sentry/nextjs'
import { signOut, useSession } from 'next-auth/react'
import { createContext, ReactNode, useContext, useEffect } from 'react'

import Preloader from 'components/Preloader'
import useAccountEvent from 'hooks/useAccountEvent'
import useUserActiveEvent from 'hooks/useUserActiveEvent'
import { useUserLanguagesQuery } from 'graphql/queries/userLanguages.gen'
import { WorkshopDataType, WorkshopStatus } from 'components/workshop/utils'
import { useUserWorkshopDataQuery } from 'graphql/workshop/UserWorkshopData.gen'

export type UserContextType = { children?: ReactNode }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UserContext = createContext<ReturnType<typeof useInit>>(null as any)

const useInit = () => {
  const session = useSession()

  const { accountUpdated } = useAccountEvent(session.data?.user.accountId)

  const { data } = useUserActiveEvent(session?.data?.user.id)

  // get all supported languages
  const { data: languagesData } = useUserLanguagesQuery()
  const userLanguages = languagesData?.userLanguages?.nodes ?? []

  // get workshop data
  const { data: workshopData } = useUserWorkshopDataQuery({
    variables: { userId: session.data?.user?.id || '' },
    skip: !session.data?.user?.id,
  })
  // get the upcoming workshop data in const wsData of type "WorkshopDataType | undefined"
  const wsData = workshopData?.user?.wsData as WorkshopDataType | undefined

  useEffect(() => {
    if (session.data?.user) {
      Sentry.setContext('user', { ...session.data.user })
    }
  }, [session.data?.user])

  // if user's organization is archived (deleted), sign them out
  // if user is archived or removed, sign them out
  useEffect(() => {
    if (
      (accountUpdated && accountUpdated.account?.isArchived) ||
      (data && (data.userUpdated?.user?.isArchived || data.userUpdated?.user?.isDeleted))
    ) {
      signOut()
    }
  }, [accountUpdated, data])

  return {
    status: session.status,
    userId: session.data?.user.id,
    user: session.data?.user,
    isProfileQuestionnaireRequired:
      (wsData &&
        !wsData.profile_questionnaire_id &&
        wsData.status === WorkshopStatus.ProfileQuestionnaireRequired) ||
      false,
    isPreWorkshopSurveyRequired:
      (wsData &&
        !wsData.pre_workshop_survey_id &&
        wsData.status === WorkshopStatus.SurveyRequired) ||
      false,
    isPreWorkRequired: (wsData && wsData.status === WorkshopStatus.PreWorkRequired) || false,
    shouldShowWsProfilePage: wsData?.level === 'LV3',
    shouldRedirectToPeerFeedbackSurvey: session.data?.shouldRedirectToPeerFeedbackSurvey,
    shouldRedirectToWorkshopFeedback: session.data?.shouldRedirectToWorkshopFeedback,
    accountId: session.data?.user.accountId ?? '',
    userLanguages,
  }
}

export const UserProvider = ({ children }: UserContextType) => {
  const state = useInit()

  const { status } = state

  if (status === 'loading') return <Preloader fullscreen />

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>
}

export const useUser = () => {
  return useContext(UserContext)
}
